<script>
  export default {
    data: () => ({
      dataColumns: [
        { caption: 'Job Number', field: 'ticket_no' },
        { caption: 'Job Type', field: 'job_type' },
        { caption: 'Description', field: 'problem_description' },
        {
          caption: 'Report Date',
          field: 'preview_report_date',
          dataType: 'datetime',
          format: '',
        },
        { caption: 'Job Status', field: 'preview_job_status' },
      ],
    }),
    props: {
      dataSource: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      handleEvent(event) {
        const url = `/pages/services-tools/job-order/view?id=${event.key}`;
        window.open(url, '_blank');
      },
    },
  };
</script>

<template>
  <span>
    <b-row>
      <b-col>
        <h2>Job Order(s)</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @row-click="handleEvent($event)"
        >
          <DxEditing
            :allow-updating="false"
            :allow-deleting="false"
            :allow-adding="false"
            mode="row"
          />

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn
            v-for="(item, index) in dataColumns"
            :key="index"
            :data-field="item.field"
            :caption="item.caption"
            :format="item.format"
            :data-type="item.dataType"
          />
        </DxDataGrid>
      </b-col>
    </b-row>
  </span>
</template>
